<template>
  <!-- 等待審核中start -->
  <!-- <div class=" flex justify-center items-center my-40">
    <div class=" items-center">
      <div class=" text-3xl mb-10 font-bold">等待審核中</div>
      <div class=" text-base">我們正在為您審核供應商資料，審核通過會直接開通頁面；<br>您可以進一步設定公司資料。</div>
    </div>
  </div> -->
  <!-- 等待審核中end -->

  <!-- 您的申請已經完成start -->
  <!-- <div class=" flex justify-center items-center my-40">
    <div class=" items-center">
      <div class=" text-3xl mb-10 font-bold">您的申請已經完成</div>
      <div class=" text-base">平台正在為您開通。</div>
    </div>
  </div> -->
  <!-- 您的申請已經完成end -->

  <!-- 建立供應商身份start -->
  <!-- <div class=" flex justify-center items-center my-40">
		<div class="pl-5">
			<div class="mb-10 text-base text-gray-600">
				本平台提供您建立公司資料，成為平台供應商。<br>
				成為平台供應商將享有以下服務：
				<ul class=" list-decimal pl-5 mt-5 mb-10">
					<li>建立公司完整資料，與平台會員交流。</li>
					<li>上架公司產品資訊。</li>
					<li>於軟協數位展覽入選展出公司產品與公司介紹。開拓公司商機。</li>
				</ul>
				申請成為平台供應商，請提供以下資料進行審核：
				<ul class=" list-decimal pl-5 mt-5 mb-10">
					<li>政府核准設立之公司統編。</li>
					<li>公司登記名稱。</li>
					<li>公司登記地址。</li>
					<li>公司核准設立日期。</li>
					<li>公司資本額。</li>
				</ul>
			</div>
			<a href="javascript:;" data-toggle="modal" data-target="#company-modal" class="button button--lg  text-white bg-theme-1 shadow-md mr-2  inline-flex items-center">
				<ArrowUpIcon class="w-4 h-4 mr-2" />
				建立供應商身份
			</a>
		</div>
  </div> -->
  <!-- 建立供應商身份end -->

  <div v-if="showPage == 'info'">
    <div class="">
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">供應商企業資料</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <button
            v-show="canEdit"
            type="button"
            @click="setPage('edit')"
            class="button text-white bg-theme-1 shadow-md mr-2 inline-flex items-center"
          >
            <Edit2Icon class="mr-2 w-4 h-4" />
            編輯
          </button>
        </div>
      </div>
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-10 mt-10">
        <div class=" text-lg text-gray-600 mb-10">公司基本資料</div>
        <div class=" lg:grid grid-cols-2 gap-5">
          <div class="">
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                公司名稱
              </div>
              <div class="  text-base text-gray-600">
                {{ vendor?.name }}
              </div>
            </div>
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                公司名稱(英)
              </div>
              <div class="  text-base text-gray-600">
                {{ vendor?.name_Eng }}
              </div>
            </div>
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                成立時間
              </div>
              <div class="  text-base text-gray-600">
                {{ vendor?.setup_Date }}
              </div>
            </div>
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                登記地址
              </div>
              <div class="  text-base text-gray-600">
                {{ vendor?.address }}
              </div>
            </div>
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                英文地址
              </div>
              <div class="  text-base text-gray-600">
                {{ vendor?.address_Eng }}
              </div>
            </div>
          </div>
          <div class="">
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                統一編號
              </div>
              <div class="  text-base text-gray-600">
                {{ vendor?.taxID }}
              </div>
            </div>
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                資本額
              </div>
              <div class="  text-base text-gray-600">
                {{ vendor?.capital_Stock_Amount }}
              </div>
            </div>
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                實收資本額
              </div>
              <div class="  text-base text-gray-600">
                {{ vendor?.paid_In_Capital_Amount }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="intro-y box p-10 mt-10">
        <div class=" text-lg text-gray-600 mb-10">公司介紹</div>
        <div class="">
          <div class=" sm:flex mb-8">
            <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
              識別標誌
            </div>
            <div class="  text-base">
              <div class="w-9.375 h-9.375 overflow-hidden">
                <img
                  v-if="vendor?.logo_ArchiveID"
                  class=" max-w-full h-auto"
                  :src="`${uploadAction}/${vendor.logo_ArchiveID}`"
                  style=" width:150px"
                  alt="識別標誌"
                />
                <img
                  v-else
                  class=" max-w-full h-auto"
                  src="@/midone/assets/images/placeholders/200x200.jpg"
                  style=" width:150px"
                  alt="識別標誌"
                />
              </div>
            </div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
              形象影片
            </div>
            <div class="  text-base text-gray-600 break-all">
              <a
                :href="vendor?.profile"
                target="_blank"
                rel="noopener noreferrer"
                >{{ vendor?.profile }}</a
              >
            </div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
              詳細介紹
            </div>
            <div class="  text-base text-gray-600">
              {{ vendor?.description }}
            </div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
              介紹(英)
            </div>
            <div class="  text-base text-gray-600">
              {{ vendor?.description_Eng }}
            </div>
          </div>
        </div>
        <div class=" lg:grid grid-cols-2 gap-5">
          <div class="">
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                官方網站
              </div>
              <div class="  text-base text-gray-600 break-all">
                <a
                  :href="vendor?.website"
                  target="_blank"
                  rel="noopener noreferrer"
                  >{{ vendor?.website }}</a
                >
              </div>
            </div>
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                客服信箱
              </div>
              <div class="  text-base text-gray-600 break-all">
                <a :href="`mailto:${vendor?.customerService_Mail}`">{{
                  vendor?.customerService_Mail
                }}</a>
              </div>
            </div>
          </div>
          <div class="">
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                公司電話
              </div>
              <div class="  text-base text-gray-600">
                {{ vendor?.tel }}
              </div>
            </div>
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                社群媒體
              </div>
              <div class="  text-base text-gray-600">
                {{ vendor?.socialMedia }}
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <!-- <div class=" sm:flex mb-8">
					<div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">業務信箱</div>
					<div class="  text-base text-gray-600 break-all">
						<a :href="`mailto:${vendor?.customerService_Mail}`">{{ vendor?.customerService_Mail }}</a>
					</div>
				</div> -->

          <div class=" sm:flex mb-8">
            <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
              海外據點
            </div>
            <div class="  text-base text-gray-600">
              {{
                vendor?.oversea_Locations
                  ? vendor?.oversea_Locations.join(",")
                  : ""
              }}
            </div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
              海外市場
            </div>
            <div class="  text-base text-gray-600">
              {{
                vendor?.oversea_Market ? vendor?.oversea_Market.join(",") : ""
              }}
            </div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
              目標市場地區
            </div>
            <div class="  text-base text-gray-600">
              {{ vendor?.target_Market ? vendor?.target_Market.join(",") : "" }}
            </div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
              產品分類
            </div>
            <div class="  text-base text-gray-600 break-all">
              {{
                vendor?.product_Category
                  ? vendor?.product_Category.join(",")
                  : ""
              }}
            </div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
              服務分類
            </div>
            <div class="  text-base text-gray-600">
              {{
                vendor?.service_Category
                  ? vendor?.service_Category.join(",")
                  : ""
              }}
            </div>
          </div>
        </div>
      </div>
      <!-- END: HTML Table Data -->
    </div>

    <!-- 申請通過modal -->
    <div
      id="pass-modal"
      class="modal flex justify-center items-center pointer-events-none"
    >
      <div
        class="modal__content modal__content--xl max-h-screen overflow-y-scroll sm:overflow-y-visible relative w-screen p-6 max-w-screen-lg pointer-events-auto"
      >
        <a
          data-dismiss="modal"
          href="javascript:;"
          class="absolute right-0 top-0 mt-6 mr-6"
        >
          <xIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="">
          <div class="font-bold text-2xl xl:text-3xl my-3">申請通過</div>
          <div class="py-12 px-0">
            <div class=" text-base">
              已確認您的供應商身份，歡迎使用本平台服務。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 建立供應商modal -->
    <div
      id="company-modal"
      class="modal flex justify-center items-center pointer-events-none"
    >
      <div
        class="modal__content modal__content--xl max-h-screen overflow-y-scroll sm:overflow-y-visible relative w-screen p-6 max-w-screen-lg pointer-events-auto"
      >
        <a
          data-dismiss="modal"
          href="javascript:;"
          class="absolute right-0 top-0 mt-6 mr-6"
        >
          <xIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div>
          <div class="">
            <div class="font-bold text-2xl xl:text-3xl my-3">
              設定供應商資料
            </div>
            <div class=" text-gray-600">
              為了保障您使用本平台個功能的權益，請確實填寫以下資料，以利我方審核。
            </div>
            <div class="mt-5 lg:grid lg:grid-cols-2 gap-2">
              <div class="">
                <label class="font-medium text-lg"
                  >公司統編
                  <span class=" text-red-400">＊</span>
                  <span class=" text-red-400 text-sm"
                    >請先輸入公司統編查詢公司資料</span
                  >
                </label>
                <input
                  type="text"
                  class="intro-x input input--lg border border-gray-300 w-full mt-2"
                  placeholder="公司統編"
                />
              </div>
              <div class=" text-gray-600 flex items-end mb-3">
                您不是軟協會員，請送出申請，我們將核對您的相關資料。
              </div>
            </div>
            <div class="mt-5 lg:grid lg:grid-cols-2 gap-4">
              <div>
                <label class="font-medium text-lg"
                  >公司名稱<span class=" text-red-400">＊</span></label
                >
                <input
                  type="text"
                  class="intro-x input input--lg border border-gray-300 w-full mt-2"
                  placeholder="公司名稱"
                />
              </div>
              <div>
                <label class="font-medium text-lg"
                  >公司英文名稱<span class=" text-red-400">＊</span></label
                >
                <input
                  type="text"
                  class="intro-x input input--lg border border-gray-300 w-full mt-2"
                  placeholder="公司英文名稱"
                />
              </div>
            </div>
            <div class="mt-5 lg:grid lg:grid-cols-2 gap-4">
              <div>
                <label class="font-medium text-lg"
                  >核准設立日期<span class=" text-red-400">＊</span></label
                >
                <Litepicker
                  v-model="date"
                  :options="{
                    autoApply: false,
                    showWeekNumbers: true,
                    dropdowns: {
                      minYear: 1990,
                      maxYear: null,
                      months: true,
                      years: true
                    }
                  }"
                  class="w-full intro-x input input--lg border border-gray-300 block"
                />
              </div>
              <div class="">
                <label class="font-medium text-lg"
                  >實收資本額<span class=" text-red-400">＊</span></label
                >
                <input
                  type="text"
                  class="intro-x input input--lg border border-gray-300 w-full"
                  placeholder="實收資本額"
                />
              </div>
            </div>
            <div class=" mt-5">
              <label class="font-medium text-lg"
                >公司登記地址<span class=" text-red-400">＊</span></label
              >
              <div class=" flex items-center">
                <div class="flex flex-col sm:flex-row items-center mr-5">
                  <label class="mr-5">縣市</label>
                  <select
                    class="input border mr-2 w-full sm:w-32 min-w-32 flex-1"
                  >
                    <option>選擇縣市</option>
                    <option>新北市</option>
                    <option>高雄市</option>
                  </select>
                </div>
                <div class="flex flex-col sm:flex-row items-center mr-5">
                  <label class="mr-5">鄉鎮區</label>
                  <select
                    class="input border mr-2 w-full sm:w-32 min-w-32 flex-1"
                  >
                    <option>選擇鄉鎮區</option>
                  </select>
                </div>
              </div>
              <input
                type="text"
                class="intro-x input input--lg border border-gray-300 w-full mt-2"
                placeholder="公司登記地址"
              />
            </div>
            <div class=" mt-5 pb-5 border-b border-theme-5">
              <label class="font-medium text-lg"
                >公司登記英文地址<span class=" text-red-400">＊</span></label
              >
              <input
                type="text"
                class="intro-x input input--lg border border-gray-300 w-full mt-2 mb-3"
                placeholder="公司登記英文地址"
              />
            </div>
            <div class="my-5 lg:grid lg:grid-cols-2 gap-4">
              <div class=" col-span-2 text-gray-600 text-lg ">個人資料</div>
              <div>
                <label class="font-medium text-lg"
                  >公司業務信箱<span class=" text-red-400">＊</span></label
                >
                <input
                  type="text"
                  class="intro-x input input--lg border border-gray-300 w-full mt-2"
                  placeholder="公司業務信箱"
                />
              </div>
              <div>
                <label class="font-medium text-lg"
                  >職稱<span class=" text-red-400">＊</span></label
                >
                <input
                  type="text"
                  class="intro-x input input--lg border border-gray-300 w-full mt-2"
                  placeholder="職稱"
                />
              </div>
              <div>
                <label class="font-medium text-lg"
                  >公司業務電話<span class=" text-red-400">＊</span></label
                >
                <div class=" flex items-center w-full">
                  <input
                    type="text"
                    class="intro-x input input--lg border border-gray-300 w-full mt-2"
                    placeholder="07-XXX-XXXX"
                  />
                  <div class="mx-3">#</div>
                  <input
                    type="text"
                    class="intro-x input input--lg border border-gray-300 w-24 mt-2"
                    placeholder="XXXX"
                  />
                </div>
              </div>
            </div>
            <div class=" flex items-center justify-end pb-5">
              <button
                type="button"
                class="button  button--lg w-full lg:w-32 text-white bg-theme-1 align-top mb-2 lg:mb-0"
              >
                送出申請
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CompanyEdit v-if="showPage == 'edit'" @setPage="setPage" />
  <CompanyApply v-if="showPage == 'apply'" @setPage="setPage" />
  <!--
  <ToUser :auth="auth" /> -->
</template>

<script lang="ts">
/* eslint-disable */

import { ref, reactive, onMounted, provide, computed } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import ToUser from "@/components/user-modal/Main.vue";
import router from "@/router";
import CompanyEdit from "@/views/company-edit/Main.vue";
import CompanyApply from "@/views/company-apply/Main.vue";

export default defineComponent({
  components: {
    ToUser,
    CompanyEdit,
    CompanyApply
  },
  setup() {
    const model = CloudFun.current?.model;
    const info = ref<any>({});
    const vendor = ref<any>({});
    const canEdit = ref(false);

    const getInfo = () => {
      return model
        ?.dispatch("user/find")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getVendor = () => {
      return model
        ?.dispatch("member/vendor")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };
    const auth = ref<any>({});

    const getAuth = () => {
      return model
        ?.dispatch("user/auth")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const init = async () => {
      auth.value = await getAuth();
      if (!auth.value.isBuyer) router.push("/company-not-vendor");
      if (!auth.value.isVendor) router.push("/company/application");
      info.value = await getInfo();

      console.log("init value", auth.value);

      canEdit.value = auth.value.position === 1 || auth.value.position === 2;

      if (info.value.isVendor) {
        vendor.value = await getVendor();
        console.log("🚀 ~ file: Main.vue ~ line 616 ~ init ~ vendor", vendor);
      } else {
        setPage("apply");
      }
    };

    onMounted(async () => {
      await init();
    });

    const showPage = ref("info");
    const setPage = async (name: string) => {
      if (name == "info") await init();
      showPage.value = name;
    };

    return {
      info,
      vendor,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`,
      auth,
      setPage,
      showPage,
      canEdit
    };
  },
  methods: {}
});
</script>
